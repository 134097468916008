import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article16032021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">CATCHING TECHNOLOGICAL WAVES</h1>
            <p>
              According to a UNCTAD Technology and Innovation Report in 2021,
              Moldova among the top 10 developing countries with more robust
              capabilities to use, adopt and adapt frontier technologies than
              their per capita GDPs would suggest.
            </p>
            <p>
              Our country ranks 8th and subclasses countries such as Russia,
              Korea, Great Britain.
            </p>
            <p>
              Their overperformance is measured as the difference between the
              actual index rankings and the estimated index rankings based on
              per capita income.
            </p>
            <p>
              Frontier technologies are those that take advantage of
              digitalization and connectivity. They include artificial
              intelligence (AI), the internet of things, big data, blockchain,
              3D printing, robotics, drones, gene editing, nanotechnology, and
              solar photovoltaic.
            </p>

            <p className="bold">
              More:{" "}
              <a href="https://unctad.org/news/few-developing-countries-overperform-frontier-technologies-most-lag-behind?fbclid=IwAR0rrw8sG_sxheeytuYyy1yd2qzLv01PlvgXRD4mp9yLmkTcykOXXhtPZbo">
                Link
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article16032021;
